import React from 'react'
import { Element } from 'react-scroll'
import { graphql } from 'gatsby'

import PageWrapper from '../components/PageWrapper'
import Hero from '../sections/landing1/Hero'
import { SliceZone } from '../components/SliceZone'
import SEO from '../components/seo'

const PageTemplate = ({data}) => {
  if (!data) return null
  const doc = data.prismicPage

  return (
    <>
      <SEO title={doc.data.title.text} description={doc.data.seo_description.text}/>

      <PageWrapper darkTheme={false}>
      <SliceZone sliceZone={doc.data.body} />
      </PageWrapper>
    </>
  )
}
export default PageTemplate

export const query = graphql`
  query PageQuery($id: String) {
    prismicPage(id: {eq: $id}) {
      data {
        title {
          text
        }
        seo_description {
          text
        }
        body {
          ... on PrismicSliceType {
            slice_type
          }
          ...PageDataBodyHeroCenterText
          ...PageDataBodyAboutUsTextBlock
          ...PageDataBodyMeetTheTeam
          ...PageDataBodyRichTextWithCta
          ...PageDataBodyWhatWeDo
          ...PageDataBodyHeroRightText
          ...PageDataBodyOurServicesPage
        }
      }
    }
  }
`

